.ul-nav1 {
    list-style: none;
    margin-bottom: 0 !important;
  }
  
  a {
    text-decoration: none;
  }
  
  .headerHome {
    /* position: sticky; */
    top: 0px;
    background-color: #1c9def;
    width: 100%;
    z-index: 2000;
  }
  .headerGallery{
    position: sticky;
    top: 0px;
    background-color: #1c9def;
    width: 100%;
  }
  
  .section {
    position: relative;
    height: calc(100vh - 3rem);
    width: 100%;
    overflow: hidden;
  }
  
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(56, 165, 238, 0.5);
  }
  
  .logo-container {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .nav-btn {
    flex: 3;
    display: flex;
  }
  
  .nav-links {
    flex: 2;
  }
  
  .log-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  
  .logo {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 3rem;
  }
  
  .logo span {
    font-weight: 300;
  }
  
  .btn {
    display: inline-block;
    padding: 0.5rem 1.3rem;
    font-size: 0.8rem;
    border: 2px solid #fff;
    border-radius: 2rem;
    line-height: 1;
    margin: 0 0.2rem;
    transition: 0.3s;
    text-transform: uppercase;
  }
  
  .btn.solid,
  .btn.transparent:hover {
    background-color: #fff;
    color: #1c9def;
  }
  
  .btn.transparent,
  .btn.solid:hover {
    background-color: transparent;
    color: #fff;
  }
  
  .nav-links > .ul-nav1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .nav-link {
    position: relative;
  }
  
  .nav-link > a {
    line-height: 3rem;
    color: #fff;
    padding: 0 0.8rem;
    letter-spacing: 1px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s;
  }
  
  .nav-link > a > i {
    margin-left: 0.2rem;
  }
  
  .nav-link:hover > a {
    transform: scale(1.1);
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 16rem;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .dropdown .ul-nav2 {
    position: relative;
    list-style: none !important;
  }
  
  .dropdown-link > a {
    display: flex;
    background-color: #fff;
    color: #000;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
  }
  
  .dropdown-link:hover > a {
    background-color: #1c9def;
    color: #fff;
  }
  
  .dropdown-link:not(:nth-last-child(2)) {
    border-bottom: 1px solid #efefef;
  }
  
  .dropdown-link i {
    transform: rotate(-90deg);
  }
  
  .arrow {
    position: absolute;
    width: 11px;
    height: 11px;
    top: -5.5px;
    left: 32px;
    background-color: #fff;
    transform: rotate(45deg);
    cursor: pointer;
    transition: 0.3s;
    z-index: -1;
  }
  
  .dropdown-link:first-child:hover ~ .arrow {
    background-color: #1c9def;
  }
  
  .dropdown-link {
    position: relative;
  }
  
  .dropdown.second {
    top: 0;
    left: 100%;
    padding-left: 0.8rem;
    cursor: pointer;
    transform: translateX(10px);
  }
  
  .dropdown.second .arrow {
    top: 10px;
    left: -5.5px;
  }
  
  .nav-link:hover > .dropdown,
  .dropdown-link:hover > .dropdown {
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
  }
  
  .hamburger-menu-container {
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
  }
  
  .hamburger-menu {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .hamburger-menu div {
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    z-index: 1001;
    transition: 0.5s;
  }
  
  .hamburger-menu div:before,
  .hamburger-menu div:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: #fff;
    border-radius: 3px;
    transition: 0.5s;
  }
  
  .hamburger-menu div:before {
    transform: translateY(-7px);
  }
  
  .hamburger-menu div:after {
    transform: translateY(7px);
  }
  
  #check {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
    display: none;
  }
  
  #check:checked ~ .hamburger-menu-container .hamburger-menu div {
    background-color: transparent;
  }
  
  #check:checked ~ .hamburger-menu-container .hamburger-menu div:before {
    transform: translateY(0) rotate(-45deg);
  }
  
  #check:checked ~ .hamburger-menu-container .hamburger-menu div:after {
    transform: translateY(0) rotate(45deg);
  }

  .ul-nav1 .dropdown{
    z-index: 1000 !important;
  }
  
  @keyframes animation {
    from {
      opacity: 0;
      transform: translateY(15px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  
  @media (max-width: 920px) {
    .hamburger-menu-container {
      display: flex;
    }
  
    #check {
      display: block;
    }
  
    .nav-btn {
      position: fixed;
      height: calc(100vh - 3rem);
      top: 3rem;
      left: 0;
      width: 100%;
      background-color: #69bde7;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: auto;
      transform: translateX(100%);
      transition: 0.65s;
    }
  
    #check:checked ~ .nav-btn {
      transform: translateX(0);
    }
  
    #check:checked ~ .nav-btn .nav-link,
    #check:checked ~ .nav-btn .log-sign {
      animation: animation 0.5s ease forwards var(--i);
    }
  
    .nav-links {
      flex: initial;
      width: 100%;
    }
  
    .nav-links > .ul-nav2 {
      flex-direction: column;
    }
  
    .nav-link {
      width: 100%;
      /* opacity: 0; */
      transform: translateY(15px);
    }
  
    .nav-link > a {
      line-height: 1;
      padding: 1.6rem 2rem;
    }
  
    .nav-link:hover > a {
      transform: scale(1);
      background-color: #1c9def;
    }
  
    .dropdown,
    .dropdown.second {
      position: initial;
      top: initial;
      left: initial;
      transform: initial;
      opacity: 1;
      pointer-events: auto;
      width: 100%;
      list-style: none !important;
      padding: 0;
      background-color: #1c9def;
      display: none;
    }
    .secon ul {
      list-style: none !important;
    }
    .nav-link:hover > .dropdown,
    .dropdown-link:hover > .dropdown {
      display: block;
      background-color:#079ECB
    }
  
    .nav-link:hover > a > i,
    .dropdown-link:hover > a > i {
      transform: rotate(360deg);
      background-color:#079ECB

    }
  
    .dropdown-link > a {
      background-color: transparent;
      color: #fff;
      padding: 1.2rem 2rem;
      line-height: 1;
    }
  
    .dropdown.second .dropdown-link > a {
      padding: 1.2rem 2rem 1.2rem 3rem;
    }
  
    .dropdown.second .dropdown.second .dropdown-link > a {
      padding: 1.2rem 2rem 1.2rem 4rem;
    }
  
    .dropdown-link:not(:nth-last-child(2)) {
      border-bottom: none;
    }
  
    .arrow {
      z-index: 1;
      background-color: #1c9def;
      left: 10%;
      transform: scale(1.1) rotate(45deg);
      transition: 0.5s;
    }
  
    .nav-link:hover .arrow {
      background-color: #1c9def;
    }
  
    .dropdown .dropdown .arrow {
      display: none;
    }
  
    .dropdown-link:hover > a {
      background-color: #1c9def;
    }
  
    .dropdown-link:first-child:hover ~ .arrow {
      background-color: #1c9def;
    }
  
    .nav-link > a > i {
      font-size: 1.1rem;
      transform: rotate(-90deg);
      transition: 0.7s;
    }
  
    .dropdown i {
      font-size: 1rem;
      transition: 0.7s;
    }
  
    .log-sign {
      flex: initial;
      width: 100%;
      padding: 1.5rem 1.9rem;
      justify-content: flex-start;
      opacity: 0;
      transform: translateY(15px);
    }
  }
  
  .sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: #fff9f9;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: #060606;
    display: block;
    transition: 0.3s;
  }
  
  .sidebar a:hover {
    color: #5a19f3;
  }
  
  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 20px;
    margin-left: 50px;
  }

  
  .openbtn {
    cursor: pointer;
    background-color: #1c9def;
    color: white;
    padding: 10px 0px;
    border: none;
    width: 100%;
  }
  
  .openbtn:hover {
    background-color: #1c9def;
  }
  
  #main {
    transition: margin-left 0.5s;
  }
  